<template>
  <q-select
    v-model="selectedOption"
    :options="listOption"
    bg-color="grey-3"
    color="default-pink"
    class="col-11"
    :multiple="isMultiple"
    outlined
    dense
    emit-value
    map-options
    use-input
    input-debounce="0"
    @filter="
      (val, update) => filterSelect(val, update, options, listOption)
    "
    @update:model-value="val => $emit('selectValue', val)"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey"> Não encontrado </q-item-section>
      </q-item>
    </template>
    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
      <q-item v-bind="itemProps">
        <q-item-section>
          <q-item-label v-html="opt.label" />
        </q-item-section>
        <q-item-section side>
          <q-toggle
            :model-value="selected"
            @update:model-value="toggleOption(opt)"
          />
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:selected>
      <label
        >{{
          selectedOption != null ? selectedOption.length : 0
        }}
        {{label}}
      </label>
    </template>
  </q-select>
</template>

<script>
//----VUEJS
import { ref } from "vue";

export default {
  name: "Select",
  props:{
    options: Array, 
    listOption: Array,
    label: String,
    isMultiple:{
      type: Boolean,
      default:true
    }
  },
  emits:['selectValue'],
  setup(){
    //-----VARIABLES
    let selectedOption = ref([]);

    //-----FUNCTIONS
    function filterSelect(val, update, options, list) {
      if (val === "") {
        update(() => {
          options = list;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        options = list.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    return{
      //-----VARIABLES
      selectedOption,

      //------FUNCTIONS
      filterSelect
    }
  }
};
</script>
<style lang="sass" scoped>

</style>