import http from "../http-file";

export default class ReportsServices {
  async reportPost(reportId, data) {
    let res = http.post(`/v1/report/report-post?reportId=${reportId}`, data);
    return res;
  }

  async reportCourses(reportId, data) {
    let res = http.post(`/v1/report/report-courses?reportId=${reportId}`, data);
    return res;
  }

  async reportUsers(reportId, data) {
    let res = http.post(`/v1/report/report-users?reportId=${reportId}`, data);
    return res;
  }

  async reportWiki(reportId, data) {
    let res = http.post(`/v1/report/report-wikis?reportId=${reportId}`, data);
    return res;
  }

  async reportUsersByGroup(reportId) {
    let res = http.post(`/v1/report/report-users-by-group?groupId=${reportId}`);
    return res;
  }

  async reportMood(reportId, data) {
    let res = http.post(`/v1/report/report-emoji?reportId=${reportId}`, data);
    return res;
  }
  async reportPoll(reportId, data) {
    let res = http.post(`/v1/report/report-poll?reportId=${reportId}`, data);
    return res;
  }
  async reportChat(reportId, data) {
    let res = http.post(`/v1/report/report-chat?reportId=${reportId}`, data);
    return res;
  }
  async reportLightningTest(reportId, data) {
    let res = http.post(
      `/v1/report/report-lightning-test?reportId=${reportId}`,
      data
    );
    return res;
  }
  async reportInteracation(reportId, data) {
    let res = http.post(
      `v1/report/report-interaction?reportId=${reportId}`,
      data
    );
    return res;
  }
  async reportOnlineLesson(data) {
    let res = http.post(
      `/v1/report/report-live-class?reportId=15`,
      data
    );
    return res;
  }
}
