<template>
  <q-select
    v-model="selectedOption"
    :options="options"
    bg-color="grey-3"
    color="default-pink"
    class="col-11"
    :multiple="isMultiple"
    outlined
    dense
    emit-value
    map-options
    use-input
    use-chips
    input-debounce="0"
    @update:model-value="val => $emit('selectValue', val)"
    @filter="(val, update) => filterSelect(val, update)"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey"> Não encontrado </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
//----VUEJS
import { ref } from "vue";

export default {
  name: "SelectSearch",

  props: {
    searchFunction:Function,
    filterFunction: Function,
    isMultiple:{
      type: Boolean,
      default:true
    }
  },

  emits: ["selectValue"],

  setup(props) {
    //-----VARIABLES
    let selectedOption = ref([]);
    let options = ref([]);

    //-----FUNCTIONS
    async function filterSelect(val, update) {

      if (val === "") {
        return;
      }
      
      let result = await props.searchFunction(val, update).catch((error) => {
        alertErro(error.response.data.toString());
      });
      
      update(() => {
        options.value = [];
        result.data.forEach((item) => {
          options.value.push(props.filterFunction(item))

          // options.value.push({
          //   label: item.title,
          //   value: item.postId,
          // });
        });
      });
    }


    return {
      //-----VARIABLES
      selectedOption,
      options,

      //------FUNCTIONS
      filterSelect,
    };
  },
};
</script>
<style lang="sass" scoped></style>
