<template>
  <div>
    <q-tabs
      v-model="selectedTab"
      dense
      indicator-color="transparent"
      align="justify"
    >
      <div
        v-for="tab in tabs"
        :key="tab.value"
        :class="[
          'tab-item',
          selectTab == tab.value
            ? 'text-default-pink bg-white'
            : 'text-grey bg-white tab-item-not-selected',
        ]"
      >
        <q-tab
          @click="$emit('selectedTabs', tab.value)"
          :name="tab.value"
          :label="tab.name"
          :disable="tab.permission"
        />
      </div>
    </q-tabs>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "TabNavigation",
  props: {
    tabs: Array,
    selectTab: Number,
  },
  emits: ["selectedTabs"],
  setup(props) {
    const selectedTab = ref(props.selectTab);
    watch(
      () => props.selectTab,
      (newState) => {
        selectedTab.value = newState;
      }
    );

    return {
      selectedTab,
    };
  },
};
</script>

<style lang="scss" scoped>
.tab-item {
  display: flex;
  flex: 1;
  border-bottom: 1px solid var(--q-primary);
  // border-radius: 8px;
}
.tab-item-not-selected {
  display: flex;
  flex: 1;
  border-bottom: 1px solid #e5e5e5;
}
</style>
